import { dineInFeedbackAtom } from "@iso/state/dineInFeedback";
import commonApi from "api/commonApi";
import { useSetRecoilState } from "recoil";

export { useCommonActions };

function useCommonActions() {
   const setDineInFeedback = useSetRecoilState(dineInFeedbackAtom);
   const getVersion = async () => {
      try {
         const resGet = await commonApi.getVersion();
         if (resGet) {
            return {
               status: true,
               data: resGet,
            };
         }
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getAllStore = async () => {
      try {
         const resGet = await commonApi.getAllStore();
         if (resGet) {
            return {
               status: true,
               data: resGet,
            };
         }
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getStoreByStoreCode = async (storeCode) => {
      try {
         const resGet = await commonApi.getStoreByStoreCode(storeCode);
         if (resGet) {
            setDineInFeedback((state) => ({
               ...state,
               store: {
                  ...state?.store,
                  storeDetail: resGet,
               },
               loading: false,
            }));
            return {
               status: true,
               data: resGet,
            };
         }
      } catch (error) {
         console.log(error);
         return {
            status: false,
         };
      }
   };

   const getStoreDetail = async (storeCode) => {
      try {
         const resGet = await commonApi.getStoreDetail(storeCode);

         if (resGet) {
            return {
               status: true,
               data: resGet,
            }
         }
      } catch (error) {
         return {
            status: false,
            data: {},
         };
      }
   };

   return { getVersion, getAllStore, getStoreByStoreCode, getStoreDetail };
}
