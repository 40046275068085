import { useCommonActions } from "@iso/actions/common.actions";
import { useEffect, useMemo, useState } from "react";
import { COUNTRY } from "../config";

function useStore(props) {
    const { storeCode } = props;
    const [storeDetail, setStoreDetail] = useState({});
    const [loading, setLoading] = useState(false);
    const commonActions = useCommonActions();

    useEffect(() => {
        if (storeCode) {
            handleStoreDetail(storeCode);
        }
    }, [storeCode]);

    const handleStoreDetail = async (storeCode) => {
        try {
            setLoading(true);
            const response = await commonActions.getStoreDetail(storeCode);
            setStoreDetail(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const isAutoNextStep = useMemo(() => {
        const availableCountry = [COUNTRY.ID];
        return availableCountry.includes(storeDetail?.country);
    }, [storeDetail?.country])

    return { storeDetail, loading, isAutoNextStep };
}

export default useStore;