export const API_FEEDBACK = "https://fb-api.pizza4ps.io/feedback/api";

export const API_URL_RECEIPT = "https://invoice.pizza4ps.com/eri/api";

export const API_DELI = "https://api.pizza4ps.com/v2";

export const URL_DELI = "https://delivery.pizza4ps.com";

export const API_BYOD = "https://vn-byo-api.pizza4ps.com/byod-api";

export const URL_BYOD = "https://byo.pizza4ps.com";

export const URL_BYOD_IN = "https://in-byo.pizza4ps.com";

export const URL_FB_BACKOFFICE = "https://bo.pizza4ps.com/feedback";
export const URL_TTO = "https://tto.pizza4ps.com";

export const API_TRAPI = "https://cms.pizza4ps.com";

export const TIME_RESET_FEEDBACK = "30";

export const TIME_RESET_FEEDBACK_THANKYOU = "10";

export const firebaseConfig = {
   apiKey: "AIzaSyDbFXVnMR3zoq3UT2U8D5lU8yEJiLcVpSA",
   authDomain: "map-4ps-prod.firebaseapp.com",
   databaseURL: "map-4ps-prod.firebaseapp.com",
   projectId: "map-4ps-prod",
};

export const KEY_COMMUNICATE_FEEDBACK = {
   CHANGE_LANG_EN: "CHANGE_LANG_EN",
   CHANGE_LANG_VI: "CHANGE_LANG_VI",
};

export const KEY_STORAGE = {
   CURRENTURL: "currentUrl",
   LANG: "lang",
};

export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export const DATE_FORMAT = "DD/MM/YYYY";

export const PLATFORM = "Web";

export const CHANNEL = {
   BYOD: "BYOD",
   DINE_IN: "DINE_IN",
};

export const SALES_CHANNEL = {
   BYOD: "DINE-IN-BYOD",
   BO: "DINE-IN-BO",
   TTO: "DINE-IN-TTO",
};

export const KEY_COMMUNICATE_BYOD = {
   ACTIVE_FULL_SCREEN: "ACTIVE_FULL_SCREEN",
   CLEAN_ORDER_INFO: "CLEAN_ORDER_INFO",
   HIDE: "HIDE",
};

export const TIME_CLEAN_ORDER_BYOD = 5;

export const MAX_FILES_SIZE = 1024 * 1024 * 9; // 9M

export const STORAGE_KEY = {
   VERSION: "vs",
};

export const LANG = {
   EN: "en",
   VI: "vi",
   JA: "ja",
   AS: "as",
   KOR: "ko",
};

export const COUNTRY = {
   EN: "EN",
   VN: "VN",
   JP: "JP",
   IN: "IN",
   ID: "ID",
};

export const QUESTION_TYPE = {
   SINGLE: 1,
   MULTIPLE: 2,
   INTRUCTION: 3,
};

export const ANSWER_TYPE = {
   RATING: 5,
   CHOICE: 1,
   TEXT_INPUT: 2,
   CHOICE_INPUT: 3,
};

export const SOURCE_NAME_LOGGING = "feedback-consumer";

export const BRAND_NAME = {
   IPPUDO: "Ippudo",
   Pizza4PS: "4Ps",
};

export const PARAMS = {
   LANG: 'lang'
}