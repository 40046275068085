import { API_FEEDBACK } from "@iso/config/index";
import { axiosClient } from "@iso/helpers/axios.config";

const commonApi = {
   getVersion: (params) => {
      return axiosClient.get(`${API_FEEDBACK}/common/version`, {
         params,
      });
   },
   getAllStore: (params) => {
      return axiosClient.get(`${API_FEEDBACK}/common/stores`, {
         params,
      });
   },
   getStoreByStoreCode: (storeCode) => {
      return axiosClient.get(`${API_FEEDBACK}/common/stores/${storeCode}`);
   },
   getStoreDetail: (storeCode) => {
      return axiosClient.get(`${API_FEEDBACK}/common/store-detail/${storeCode}`);
   },
};

export default commonApi;
